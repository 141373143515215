import React from "react"

const BackTop = () => {
  const scrollToRef = e => {
    e.preventDefault()
    window.scrollTo(0, 0)
  }

  return (
    <a className="c-back-top" onClick={scrollToRef} href="#">
      <img src="/images/default/back-top.svg" alt="Voltar ao topo" />
    </a>
  )
}

export default BackTop
