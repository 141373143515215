import axios from "axios";

const baseCmsURL = `${process.env.GATSBY_API_URL}`
const baseBucketURL = `${process.env.GATSBY_BUCKET_URL}`
const baseEmbedURL = `${process.env.GATSBY_EMBED_URL}`
const baseSiteURL = `${process.env.GATSBY_SITE_URL}`
const baseApiURL = `${baseCmsURL}api/public/data`
const baseGreenhouse = `${process.env.GATSBY_GREENHOUSE_URL}`
const baseFunctionGreenhouse = `${process.env.GATSBY_API_HOST}/greenhouse`
const baseMapsApiURL = `${process.env.GATSBY_API_HOST}/maps-api`

const getData = () => {
  return axios
    .get(baseApiURL)
    .then(({ data }) => data)
    .catch(err => console.log(err))
}

const getCMSUrl = path => {
  return `${baseCmsURL}${path}`
}

const getBucketUrl = path => {
  return `${baseBucketURL}${path}`
}

const getEmbedUrl = path => {
  return `${baseEmbedURL}?for=${path}`
}

const getSiteUrl = path => {
  return `${baseSiteURL}${path}`
}

const getDepartments = department => {
  return axios
    .get(`${baseGreenhouse}boards/${department}/departments?render_as=tree`)
    .then(({ data }) => data)
    .catch(err => console.log(err))
}

const getJobForm = async ({ department, jobId }) => {
  const { data } = await axios.get(
    `${baseGreenhouse}boards/${department}/jobs/${jobId}?questions=true`
  )

  return data
}

const postJobForm = async data => {
  const { status, data: responseData } = await axios.post(
    baseFunctionGreenhouse,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  if (status !== 200) {
    throw new Error("Error to status code")
  }

  return responseData
}

const getLocationResult = async (value) => {
  const config = {
    method: 'get',
    url: `${baseMapsApiURL}?search=${value}`,
  }
  return axios(config).then(res => res.data)
}

export {
  getData,
  getCMSUrl,
  getBucketUrl,
  getEmbedUrl,
  getSiteUrl,
  getDepartments,
  getJobForm,
  postJobForm,
  getLocationResult
}
