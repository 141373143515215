import React, { useState, useEffect } from "react"
import {getData} from "../../service"
import Loading from 'components/Loading'
import PropTypes from 'prop-types';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import 'scss/style.scss';

const Layout = (props) => {

  const [loaded, setLoaded] = useState(false);

  const loadedCallback = (apiData) => {
    if (props.loadedCallback) {
      props.loadedCallback(apiData);
    }
  }

  useEffect(() => {
    if (window.apiData) {
      loadedCallback(window.apiData);
      setTimeout(() => {
        setLoaded(true);
      }, 2000);
    } else {
      getData().then(res => {
        window.apiData = res;
        loadedCallback(res);
        setTimeout(() => {
          setLoaded(true);
        }, 2000);
      });
    }
  }, [])

  return (
  <div className="u-expanded">
    <Head {...props} />
    <Header/>
    <Loading loaded={loaded} />
    <div className="container-content">
      {props.children}
    </div>
    <Footer />
  </div>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  //props.loadedCallback();
};

export default Layout;