import React, { useEffect, useState } from "react"
import "scss/style.scss"
import { Link } from "gatsby"

function Header() {
  const [path, setPath] = useState()

  useEffect(() => {
    let scrollpos = window.scrollY
    const header_height = document.querySelector(".c-header").offsetHeight
    const html = document.querySelector("html")

    const add_class_on_scroll = () => {
      html.classList.add("is-scrolling")
      html.classList.add("smooth")
    }
    const remove_class_on_scroll = () => html.classList.remove("is-scrolling")

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY
      scrollpos >= header_height
        ? add_class_on_scroll()
        : remove_class_on_scroll()
    })

    let p = window.location.pathname.split("/")
    setPath(Array.isArray(p) && p.length > 1 ? p[1] : "")
  })

  function toggleClass() {
    const html = document.querySelector("html")
    html.classList.toggle("is-nav-open")
  }

  return (
    <>
      <header className="c-header">
        <h1 className="c-header__logo">
          <span className="u-sr">Jornada Stone</span>
          <Link to="/" className="c-header__links">
            <img
              src="/images/default/logo-jornada-stone.png"
              alt="Jornada Stone"
            />
          </Link>
        </h1>

        <div className="c-menu-hamburger" onClick={() => toggleClass()}>
          <img src="/images/default/menu-mobile.png" alt="Menu Mobile" />
        </div>
        <nav className="c-nav">
          <Link
            to="/times/"
            className={`c-nav__item ${path === "times" ? "is-active" : ""}`}
          >
            Times
          </Link>
          <Link
            to="/cultura/"
            className={`c-nav__item ${path === "cultura" ? "is-active" : ""}`}
          >
            Cultura
          </Link>
          <Link
            to="/educacao/"
            className={`c-nav__item ${path === "educacao" ? "is-active" : ""}`}
          >
            Educação
          </Link>
          <a
            href="https://www.stone.com.br/biblioteca/"
            target="_blank"
            rel="noreferrer"
            className="c-nav__item"
          >
            Biblioteca Stone
          </a>
          <Link
            to="/faq/"
            className={`c-nav__item ${path === "faq" ? "is-active" : ""}`}
          >
            FAQ
          </Link>
          <Link to="/times/" className="c-nav__oportunities">
            Vagas
          </Link>
        </nav>
      </header>
    </>
  )
}

export default Header
