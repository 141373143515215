import { Link } from "gatsby"
import React from "react"
import FooterLogo from "../../static/images/default/footer/footer-logo.png"
import BackToTop from "../components/BackTop"

const Footer = () => (
  <footer className="c-footer">
    <div className="u-wrapper">
      <div className="c-footer__content">
        <div className="c-footer-nav">
          <a className="c-footer-nav__logo" href="https://www.stone.co/br/" target="_blank" rel="noreferrer">
            <img src={FooterLogo} alt="Stone" />
          </a>
          <ul className="c-footer-nav__list">
            <li className="c-footer-nav__item">
              <a className="c-footer-nav__link" href="https://www.stone.co/br/" target="_blank" rel="noreferrer">
                Site da Stone
              </a>
            </li>
            <li className="c-footer-nav__item">
              <a className="c-footer-nav__link" href="https://www.stone.com.br/biblioteca" target="_blank" rel="noreferrer">
                Biblioteca Stone
              </a>
            </li>
            <li className="c-footer-nav__item">
              <Link to="/politica-privacidade" className="c-footer-nav__link">Política de Privacidade</Link>
            </li>
          </ul>
        </div>
        <div className="c-footer-contact">
          <div className="c-footer-contact__item">
            <h4 className="c-footer-contact__ttl">Para problemas de contato e dúvidas sobre a Stone, acione</h4>
            <a
              href="mailto:jornada@stone.com.br"
              className="c-footer-contact__email"
            >
              jornada@stone.com.br
            </a>
          </div>
        </div>
        <div className="c-footer-socials">
          <ul className="c-footer-socials__list">
            <li className="c-footer-socials__item">
              <a className="c-footer-socials__link" href="https://www.facebook.com/stonepagamentos/" target="_blank" rel="noreferrer" title="facebook">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.868"
                  height="21.951"
                  viewBox="0 0 11.868 21.951"
                >
                  <path
                    fill="#fff"
                    d="M33.5 0h-2.849c-3.2 0-5.265 2.12-5.265 5.4v2.493h-2.861a.448.448 0 0 0-.448.448v3.609a.448.448 0 0 0 .448.448h2.862V21.5a.447.447 0 0 0 .448.448h3.734a.448.448 0 0 0 .448-.448v-9.1h3.346a.447.447 0 0 0 .448-.448V8.341a.448.448 0 0 0-.448-.448h-3.347V5.781c0-1.015.242-1.53 1.564-1.53h1.92a.448.448 0 0 0 .447-.448V.452A.448.448 0 0 0 33.5 0z"
                    transform="translate(-22.077)"
                  />
                </svg>
              </a>
            </li>
            <li className="c-footer-socials__item">
              <a className="c-footer-socials__link" href="https://www.instagram.com/stone/" target="_blank" rel="noreferrer" title="instagram">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="instagram_1_"
                  width="23.096"
                  height="23.096"
                  data-name="instagram (1)"
                  viewBox="0 0 23.096 23.096"
                >
                  <path
                    id="Caminho_1"
                    d="M16.844 0H6.252A6.259 6.259 0 0 0 0 6.252v10.592A6.259 6.259 0 0 0 6.252 23.1h10.592a6.259 6.259 0 0 0 6.256-6.256V6.252A6.259 6.259 0 0 0 16.844 0zm-5.3 17.863a6.315 6.315 0 1 1 6.315-6.315 6.322 6.322 0 0 1-6.311 6.315zm6.47-11.141a1.866 1.866 0 1 1 1.866-1.866 1.868 1.868 0 0 1-1.866 1.866zm0 0"
                    data-name="Caminho 1"
                  />
                  <path
                    id="Caminho_2"
                    d="M150.981 146.02a4.961 4.961 0 1 0 4.961 4.961 4.967 4.967 0 0 0-4.961-4.961zm0 0"
                    data-name="Caminho 2"
                    transform="translate(-139.433 -139.433)"
                  />
                  <path
                    id="Caminho_3"
                    d="M388.5 96.3a.512.512 0 1 0 .512.512.513.513 0 0 0-.512-.512zm0 0"
                    data-name="Caminho 3"
                    transform="translate(-370.49 -91.957)"
                  />
                </svg>
              </a>
            </li>
            <li className="c-footer-socials__item">
              <a className="c-footer-socials__link" href="https://www.youtube.com/channel/UCk4L4JykgoDkN8YeuxkNqIQ" target="_blank" rel="noreferrer" title="youtube">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27.78"
                  height="19.099"
                  viewBox="0 0 27.78 19.099"
                >
                  <g>
                    <path
                      fill="#fff"
                      d="M26.6 81.84c-.754-1.34-1.571-1.587-3.236-1.681-1.663-.113-5.846-.16-9.47-.16s-7.815.047-9.477.158c-1.662.1-2.481.34-3.242 1.682C.4 83.179 0 85.485 0 89.544v.014c0 4.042.4 6.365 1.175 7.69.76 1.34 1.578 1.583 3.24 1.7 1.663.1 5.848.155 9.478.155s7.806-.057 9.471-.153c1.665-.113 2.483-.356 3.236-1.7.783-1.325 1.179-3.648 1.179-7.69v-.014c.001-4.061-.395-6.367-1.179-7.706zM10.418 94.758V84.341l8.682 5.208z"
                      transform="translate(0 -80)"
                    />
                  </g>
                </svg>
              </a>
            </li>
            <li className="c-footer-socials__item">
              <a className="c-footer-socials__link" href="https://www.linkedin.com/company/stone-pagamentos/" target="_blank" rel="noreferrer" title="linkedin">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="linkedin"
                  width="21.581"
                  height="21.581"
                  viewBox="0 0 21.581 21.581"
                >
                  <path
                    id="Caminho_31"
                    d="M22.431 22.344h.005v-7.915c0-3.872-.834-6.855-5.36-6.855A4.7 4.7 0 0 0 12.844 9.9h-.063V7.935H8.489v14.408h4.469v-7.134c0-1.878.356-3.695 2.682-3.695 2.292 0 2.326 2.144 2.326 3.815v7.015z"
                    transform="translate(-.856 -.763)"
                  />
                  <path
                    id="Caminho_32"
                    d="M.4 7.977h4.47v14.408H.4z"
                    transform="translate(-.04 -.804)"
                  />
                  <path
                    id="Caminho_33"
                    d="M2.592 0a2.6 2.6 0 1 0 2.591 2.592A2.592 2.592 0 0 0 2.592 0z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <BackToTop />
  </footer>
)

export default Footer
