import React, { useEffect, useState } from "react"

const Loading = props => {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(props.loaded);
  },[props.loaded])

  return (
    <>
      <div className={`c-loader ${loaded ? "c-loader--out" : ""}`}>
        <div className="c-loader__loading">
          <div className="c-loader__animation">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src="/images/default/logo-jornada-stone.png" alt="Jornada Stone" />
        </div>
      </div>
    </>
  )
}

export default Loading
